import { getAPI, postAPI } from "../services/api-services";

const baseURL = process.env.REACT_APP_API;

export const getPermanentIssueRegister = async (
	ruleType = null,
	token = "",
) => {
	const queryParams = ruleType ? `?rule_type=${ruleType}` : "";
	const url = `${baseURL}/api/getRulesAdmin${queryParams}`;
	const { data, loading, error } = await getAPI(url, token);
	return { data, error, loading };
};

export const postPermanentIssueRegister = async (body, token = "") => {
	const url = `${baseURL}/api/updateRulesAdmin`;
	const { data, loading, error } = await postAPI(url, body, token);
	return { data, error, loading };
};
