import { createContext, useEffect, useState } from "react";

const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
	// I have no idea why these exist, it's a redirect on reload thing?
	const [currentRoute, setCurrentRoute] = useState(() => {
		const savedCurrentRoute = localStorage.getItem("currentRoute");
		return savedCurrentRoute ? savedCurrentRoute : "/";
	});

	const [selectedModule, setSelectedModule] = useState("Wedge Builder");

	useEffect(() => {
		localStorage.setItem("currentRoute", currentRoute);
		localStorage.setItem("selectedModule", selectedModule);
	}, [currentRoute, selectedModule]);

	return (
		<GlobalStateContext.Provider
			value={{
				currentRoute,
				setCurrentRoute,
				selectedModule,
				setSelectedModule,
			}}
		>
			{children}
		</GlobalStateContext.Provider>
	);
};

export { GlobalStateContext, GlobalStateProvider };
