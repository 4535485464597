import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/dist";
import Header from "../../shared/layouts/header";

import { Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAccessToken from "../../hooks/useAccessToken";
import { GlobalStateContext } from "../../shared/GlobalStateContext";
import InputBoxCellRenderer from "../../shared/ag-grid/cellrenderer/inputBoxCellRenderer";
import FRPAgGrid from "../../shared/ag-grid/frp-ag-grid";
import {
	getPermanentIssueRegister,
	postPermanentIssueRegister,
} from "../../shared/services/permanent-issue-register-api-services";

const PermanentIssueRegister = () => {
	const gridRef = useRef(null);
	const [openLoading, setOpenLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [rowData, setRowData] = useState([]);
	const [isOpenForValidation, setIsOpenForValidation] = useState(false);
	const [validationErrMsg, setValidationErrMsg] = useState(null);
	const [originalRowData, setOriginalRowData] = useState([]);
	const { selectedModule } = useContext(GlobalStateContext);
	const accessTokenProvider = useAccessToken();
	let editedRowData = [];

	const watchRowProperties = [
		"permanent_issue_list",
		"permanent_non_issue_list",
		"permanent_ignore_list",
	];

	const _location = useLocation();
	const navigate = useNavigate();

	async function fetchPermanentIssueRegister(selectedModule) {
		setOpenLoading(true);
		const ruleType = selectedModule === "Wedge Builder" ? "WEDGE" : "GAP";
		const token = await accessTokenProvider.getToken();
		const data = await getPermanentIssueRegister(ruleType, token);
		if (data.data !== null) {
			setOriginalRowData(JSON.parse(JSON.stringify(data.data)));
			setRowData(data.data);
			setOpenLoading(false);
		} else {
			setOpenLoading(false);
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <function changes on every re-render>
	useEffect(() => {
		fetchPermanentIssueRegister(selectedModule);
	}, [selectedModule]);

	const colDefs = [
		{
			headerName: "Line of Business",
			field: "line_of_business",
			filter: "agTextColumnFilter",
			width: 30,
			minWidth: 200,
			maxWidth: 250,
			autoHeight: true,
			wrapHeaderText: true,
			flex: 1,
		},
		{
			headerName: "Issue Name",
			field: "rule_name",
			filter: "agTextColumnFilter",
			width: 30,
			minWidth: 200,
			maxWidth: 250,
			autoHeight: true,
			wrapHeaderText: true,
			tooltipField: "rule_name",
			flex: 1,
		},
		{
			headerName: "Permanent Issue",
			field: "permanent_issue_list",
			filter: "agTextColumnFilter",
			width: 30,
			minWidth: 340,
			maxWidth: 680,
			autoHeight: true,
			wrapHeaderText: true,
			cellRenderer: InputBoxCellRenderer,
			cellRendererParams: {
				placeholder: "",
			},
			flex: 1,
		},
		{
			headerName: "Permanent Non-Issue",
			field: "permanent_non_issue_list",
			filter: "agTextColumnFilter",
			width: 30,
			minWidth: 340,
			maxWidth: 680,
			autoHeight: true,
			wrapHeaderText: true,
			cellRenderer: InputBoxCellRenderer,
			cellRendererParams: {
				placeholder: "",
			},
			flex: 1,
		},
		{
			headerName: "Permanent Ignore",
			field: "permanent_ignore_list",
			filter: "agTextColumnFilter",
			width: 30,
			minWidth: 340,
			maxWidth: 680,
			autoHeight: true,
			wrapHeaderText: true,
			cellRenderer: InputBoxCellRenderer,
			cellRendererParams: {
				placeholder: "",
			},
			flex: 1,
		},
	];

	const _delay = (ms) =>
		new Promise((resolve) => {
			setTimeout(resolve, ms);
		});

	const postPIR = async () => {
		const body = [];
		if (editedRowData.length > 0) {
			for (const row of editedRowData) {
				const updateEntry = { rule_id: row.rule_id };
				for (const property of watchRowProperties) {
					updateEntry[property] = row[property];
				}
				body.push(updateEntry);
			}
		}
		const token = await accessTokenProvider.getToken();
		const data = await postPermanentIssueRegister(JSON.stringify(body), token);
		return data;
	};

	const onSave = async () => {
		const msgErr = validation();
		if (msgErr === "") {
			if (editedRowData.length > 0) {
				setOpenLoading(true);
				const data = await postPIR();
				if (
					data.data !==
					"Rules updated successfully in schema frp with array data."
				) {
					setOpenLoading(false);
					toast.error(data.error);
					return;
				}
				fetchPermanentIssueRegister();
				setOpenLoading(false);
				toast.success(
					"The Permanent Issue Register has been successfully updated.",
				);
			}
		} else {
			setIsOpenForValidation(true);
			setValidationErrMsg(msgErr);
		}
	};

	const validation = () => {
		let errMsg = "Invalid form number\n";
		let isError = false;

		gridRef.current.api.forEachNode((rowNode) => {
			let invalidText = "";
			for (const property of watchRowProperties) {
				invalidText = isValidString(rowNode.data[property]);
				if (invalidText !== "") {
					errMsg += invalidText.trim();
					isError = true;
				}
			}
		});

		if (!isError) {
			errMsg = "";
			const filteredData = compareAndFilter(
				rowData,
				originalRowData,
				watchRowProperties,
			);
			editedRowData = [...filteredData];
		} else
			errMsg +=
				"  \nForm number with special characters and \nspaces are not allowed.";

		return errMsg;
	};

	const isValidString = (data) => {
		let invalidData = "";
		if (data != null && data !== undefined) {
			const inputValue = data.split(",");
			for (const element of inputValue) {
				const regex = /^[a-zA-Z0-9,]*$/;
				if (!regex.test(element.trim())) {
					invalidData += `${element},`;
				}
			}
		}

		return invalidData;
	};

	const compareAndFilter = (arr1, arr2, properties) => {
		return arr1.filter((item1) => {
			const item2 = arr2.find((item2) => item2.id === item1.id);
			return properties.some((property) => item2[property] !== item1[property]);
		});
	};

	const handleOK = () => {
		setValidationErrMsg(null);
		setIsOpenForValidation(false);
	};

	const onClose = () => {
		const alteredListData = compareAndFilter(
			rowData,
			originalRowData,
			watchRowProperties,
		);

		if (alteredListData.length > 0) setIsOpen(true);
		else navigate("/wb-home");
	};

	const handleDiscardChanges = () => {
		setIsOpen(false);
		toast.success(
			"The changes made to the Permanent Issue Register have been discarded.",
		);
		navigate("/wb-home");
	};

	const handleCancel = () => {
		setIsOpen(false);
	};

	return (
		<>
			<div
				id="content-wrapper"
				class="d-flex flex-column"
				style={{ flex: "1" }}
			>
				<div id="content">
					<Header />
					<div class="container-fluid">
						<hr class="sidebar-divider my-0" />
						<div class="row">
							<div class="col-xl-12 col-md-6 mb-4" />
						</div>
						<div class="row">
							<div class="col-xl-12 col-md-12 mb-4">
								<div class="card shadow mb-4">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
										<h6 class="infoMessage">
											Revise or add new form number to the Permanent Issue
											Register table
										</h6>
									</div>
									<div class="card-body">
										<div
											className={"ag-theme-quartz"}
											style={{ width: "100%", height: 655 }}
										>
											<FRPAgGrid
												gridRef={gridRef}
												columnDefs={colDefs}
												rowData={rowData}
											/>
										</div>
									</div>
									<div>
										<div class="col-xl-12 col-md-12 mb-12">
											<div className="review-button">
												<Button
													className={
														rowData.length === 0
															? "btnprimary-disable"
															: "btnprimary"
													}
													onClick={onSave}
													disabled={rowData.length === 0}
												>
													Save
												</Button>
												<Button className="btnprimary" onClick={onClose}>
													Close
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={openLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Dialog
					open={isOpen}
					onClose={handleCancel}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Are you sure you want to discard all changes made?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDiscardChanges}>Discard Changes</Button>
						<Button onClick={handleCancel}>Cancel</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={isOpenForValidation}
					onClose={handleOK}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							<pre>{validationErrMsg}</pre>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleOK}>OK</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};
export default PermanentIssueRegister;
