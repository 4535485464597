import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authConfig";

const useAuthenticatedPage = () => {
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();
	const { REACT_APP_REQUIRED_GROUP, REACT_APP_ADMIN_GROUP } = process.env;
	const [userRole, setUserRole] = useState("NONE");

	useEffect(() => {
		if (!isAuthenticated && inProgress === "none") {
			navigate("/");
			return;
		}

		if (isAuthenticated) {
			instance
				.acquireTokenSilent({
					...loginRequest,
					scopes: ["User.Read", "GroupMember.Read.All"],
					account: accounts[0],
				})
				.then(async (response) => {
					try {
						// Fetch user's groups using Microsoft Graph API
						const graphResponse = await fetch(
							"https://graph.microsoft.com/v1.0/me/memberOf",
							{
								headers: {
									Authorization: `Bearer ${response.accessToken}`,
									"Content-Type": "application/json",
								},
							},
						);

						if (!graphResponse.ok) {
							throw new Error("Failed to fetch user groups");
						}

						const groupData = await graphResponse.json();
						const groups = groupData.value.map((group) => group.displayName);

						if (
							!groups.includes(REACT_APP_REQUIRED_GROUP) &&
							!groups.includes(REACT_APP_ADMIN_GROUP)
						) {
							console.error(
								"useAuthenticatedPage: User does not belong to required group",
							);
							throw new Error("User does not belong to the required group");
						}

						instance.setActiveAccount(accounts[0]);
						setUserRole(
							groups.includes(REACT_APP_ADMIN_GROUP) ? "ADMIN" : "USER",
						);
					} catch (error) {
						console.error("useAuthenticatedPage: Error in group validation", {
							error: error.message,
							stack: error.stack,
						});
						navigate("/login-error");
					}
				})
				.catch((error) => {
					console.error("useAuthenticatedPage: Authentication error", {
						error: error.message,
						stack: error.stack,
					});
					navigate("/login-error");
				});
		}
	}, [
		instance.setActiveAccount,
		instance.acquireTokenSilent,
		accounts,
		navigate,
		isAuthenticated,
		REACT_APP_REQUIRED_GROUP,
		REACT_APP_ADMIN_GROUP,
		inProgress,
	]);

	const shouldBlockPage = !isAuthenticated && inProgress === "none";

	return {
		shouldBlockPage,
		userData: accounts?.[0] || null,
		userRole,
	};
};

export default useAuthenticatedPage;
