import { getAPI, postAPI } from "../services/api-services";

const baseURL = process.env.REACT_APP_API;

export const getOutputReview = async (caseID, token = "") => {
	const url = `${baseURL}/api/getOutputAnalysisTable?case_id=${caseID}`;
	const { data, loading, error } = await getAPI(url, token);
	return { data, error, loading };
};

export const postOutputReview = async (caseID, body, action, token = "") => {
	const url = `${baseURL}/api/saveAndConfirmResults/?case_id=${caseID}&action=${action}`;
	const { data, loading, error } = await postAPI(url, body, token);
	return { data, error, loading };
};
