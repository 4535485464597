import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SignInButton } from "../SignInButton";

import { loginRequest } from "../../authConfig";

export function Login() {
	const { instance, inProgress } = useMsal();
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (isAuthenticated && inProgress === "none") {
			navigate("/wb-home");
		} else if (inProgress === "none") {
			instance.loginRedirect(loginRequest);
			console.log("autoLogin goes here");
		}
	}, [instance.loginRedirect, isAuthenticated, navigate, inProgress]);
	return (
		<div className="container cotainer-display" style={{ flex: "1" }}>
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-12 col-md-12">
					<div className="card o-hidden border-0 shadow-lg my-5">
						<div className="card-body p-0">
							<div className="row">
								<div className="col-lg-12">
									<div className="p-5 text-center">
										<div className="text-center">
											<img className="logo" src="img/FRPLogo.svg" alt="logo" />
										</div>
										<div>
											This application needs you to be logged in to use it.
										</div>
										<SignInButton />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
