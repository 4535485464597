import { Suspense, useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import IdleLogout from "./idleLogout";

import "./App.css";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		msalInstance.initialize().then(() => {
			setIsInitialized(true);
		});
	}, []);

	if (!isInitialized) {
		return <div>Initializing...</div>;
	}
	return (
		<MsalProvider instance={msalInstance}>
			<Suspense fallback="...loading">
				<HashRouter>
					<IdleLogout />
				</HashRouter>
				<ToastContainer
					theme="colored"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Suspense>
		</MsalProvider>
	);
}

export default App;
