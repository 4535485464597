import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

const useAccessToken = (scopes = []) => {
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts } = useMsal();

	const getToken = async () => {
		if (!isAuthenticated) {
			return undefined;
		}

		if (!accounts[0]) {
			console.error("No active account");
			return undefined;
		}

		const request = {
			scopes: scopes,
			account: accounts[0],
		};

		try {
			const response = await instance.acquireTokenSilent(request);
			return response.idToken;
		} catch (error) {
			console.error("acquireTokenSilent error", error);
			throw error;
		}
	};

	return { getToken };
};

export default useAccessToken;
