import { useMsal } from "@azure/msal-react";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { loginRequest } from "../authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
	const { instance } = useMsal();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogin = async (loginType) => {
		if (instance.getActiveAccount()) {
			return; // Already logged in
		}

		try {
			// Check if interaction is in progress
			const interactionStatus = instance.inProgress;
			console.log("interactionStatus", interactionStatus, loginRequest);

			handleClose(); // Close the menu before starting login

			if (loginType === "popup") {
				await instance.loginPopup(loginRequest);
			} else if (loginType === "redirect") {
				await instance.loginRedirect(loginRequest);
			}
		} catch (error) {
			if (error.errorCode === "interaction_in_progress") {
				console.log("Another authentication interaction is in progress");
			} else {
				console.error("Login failed:", error);
			}
		}
	};
	return (
		<>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				Sign in
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={() => handleLogin("popup")}>Using popup</MenuItem>
				<MenuItem onClick={() => handleLogin("redirect")}>
					Using Redirect
				</MenuItem>
			</Menu>
		</>
	);
};
