import { useNavigate } from "react-router-dom";
import { SignOutButton } from "../SignOutButton";
import "./LoginError.css";

const LoginError = () => {
	const navigate = useNavigate();

	const handleTryAgain = () => {
		navigate("/");
	};

	return (
		<div className="login-error-container">
			<div className="login-error-box">
				<h1 className="login-error-title">Login Error</h1>
				<p className="login-error-message">
					There was a problem signing you in. Please try again.
				</p>
				<button
					onClick={handleTryAgain}
					type="button"
					className="try-again-button"
					data-testid="try-again-button"
				>
					Try signing in again
				</button>
				<span className="separator">or</span>
				<SignOutButton />
			</div>
		</div>
	);
};

export default LoginError;
