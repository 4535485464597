import { useContext } from "react";
import { Navigate } from "react-router-dom";
import useAuthenticatedPage from "../hooks/useAuthenticatedPage";
import { GlobalStateContext } from "./GlobalStateContext";

const Protected = ({ children, component }) => {
	const { setCurrentRoute } = useContext(GlobalStateContext);
	const { userRole, shouldBlockPage } = useAuthenticatedPage();

	if (shouldBlockPage) {
		return <Navigate to="/" replace />;
	}

	if (component === "permanent-issue-register" && userRole !== "ADMIN") {
		return <Navigate to="/" replace />;
	}

	setCurrentRoute(component);
	return children;
};
export default Protected;
