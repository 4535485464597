import { useMsal } from "@azure/msal-react";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthenticatedPage from "../../hooks/useAuthenticatedPage";
import { handleKeyDown } from "../../shared/utils";
import { GlobalStateContext } from "../GlobalStateContext";
import "./layout.css";

const Header = ({ caseID }) => {
	const options = [
		{ label: "Wedge Builder", Id: 0 },
		{ label: "Gap Analysis", Id: 1 },
	];

	const [isOpen, setIsOpen] = useState(false);
	const { selectedModule, setSelectedModule, userName } =
		useContext(GlobalStateContext);

	const { userRole } = useAuthenticatedPage();
	const { instance } = useMsal();

	const navigate = useNavigate();

	const onModuleSelection = (newValue) => {
		setSelectedModule(newValue.label);
	};

	const handleLogout = () => {
		setIsOpen(true);
	};

	const handleCancel = () => {
		setIsOpen(false);
	};

	const handleConfirmLogout = () => {
		setIsOpen(false);
		toast.success("You have successfully logged out");
		//sessionStorage.clear();
		localStorage.clear();
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
	};

	const handleAdminPage = () => {
		navigate("/permanent-issue-register");
	};

	const navigateToHome = () => {
		navigate("/wb-home");
		setSelectedModule("Wedge Builder");
	};

	return (
		<>
			<nav class="navbar navbar-expand navbar-light bg-color topbar mb-4 static-top shadow">
				<div>
					<img
						class="logo"
						src="img/FRPLogo.svg"
						alt="logo"
						onClick={navigateToHome}
						onKeyDown={(e) => handleKeyDown(e, navigateToHome)}
					/>
				</div>
				<div className="autoComplete-div">
					<Stack spacing={1} sx={{ width: 175 }}>
						<Autocomplete
							value={selectedModule}
							disableClearable
							id="module-dropdown"
							options={options}
							sx={{ width: 250, minHeight: 1 }}
							ListboxProps={{ style: { maxHeight: 250 } }}
							onChange={(_e, v) => onModuleSelection(v)}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.Id}>
										<span className="autoComplete-list">{option.label}</span>
									</li>
								);
							}}
							renderInput={(params) => (
								<TextField {...params} variant="standard" size="small" />
							)}
						/>
					</Stack>
				</div>
				<span className="case-info">{caseID}</span>
				<ul class="navbar-nav ml-auto">
					<li class="nav-item dropdown no-arrow">
						<button
							type="submit"
							class="nav-link dropdown-toggle"
							id="userDropdown"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							style={{ height: "100%" }}
						>
							<span class="mr-2 d-none d-lg-inline user-text-color small">
								{userName}
							</span>
							<span className="avatar">
								{userName.length > 0
									? `${userName.substr(0, 1)}${
											userName.split(" ").length > 0
												? userName.split(" ")[1].substr(0, 1)
												: ""
										}`
									: ""}
							</span>
						</button>
						<div
							class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
							aria-labelledby="userDropdown"
						>
							<div style={{ display: userRole === "ADMIN" ? "block" : "none" }}>
								<button
									type="submit"
									class="dropdown-item"
									data-toggle="modal"
									onClick={handleAdminPage}
								>
									<i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
									Permanent Issue Register
								</button>
								<div class="dropdown-divider" />
							</div>
							<button
								type="submit"
								class="dropdown-item"
								data-toggle="modal"
								onClick={handleLogout}
							>
								<i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
								Logout
							</button>
						</div>
					</li>
				</ul>
			</nav>
			<Dialog
				open={isOpen}
				onClose={handleCancel}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure you want to logout?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button onClick={handleConfirmLogout}>Logout</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
export default Header;
