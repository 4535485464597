import { useMsal } from "@azure/msal-react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "./app.routing";

const IdleLogout = () => {
	const navigate = useNavigate();
	const { instance } = useMsal();

	const idleTimeout = 900000; // 15 minutes

	const handleOnIdle = () => {
		toast.success("Session has expired !! Please Re-Login");
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
		navigate("/");
	};
	// Is this because of the custom auth system, are we really expiring tokens on the frontend?
	const _idleTimer = useIdleTimer({
		timeout: idleTimeout,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	return <>{routes}</>;
};

export default IdleLogout;
