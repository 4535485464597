import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

/**
 * Renders a sign-out button that uses redirect logout
 */
export const SignOutButton = () => {
	const { instance } = useMsal();

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
	};

	return <Button onClick={handleLogout}>Logout</Button>;
};
